@font-face {
    font-family: 'Pragmatica';
    src: url('../fonts/Pragmatica-Book.eot') format('embedded-opentype'),
		 url('../fonts/Pragmatica-Book.woff') format('woff'),
		 url('../fonts/Pragmatica-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Pragmatica';
    src: url('../fonts/Pragmatica-Bold.eot') format('embedded-opentype'),
		 url('../fonts/Pragmatica-Bold.woff') format('woff'),
		 url('../fonts/Pragmatica-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Pragmatica';
    src: url('../fonts/Pragmatica-LightOblique.eot') format('embedded-opentype'),
		 url('../fonts/Pragmatica-LightOblique.woff') format('woff'),
		 url('../fonts/Pragmatica-LightOblique.ttf') format('truetype');
    font-weight: 300;
    font-style: oblique;
}
@font-face {
    font-family: 'Romul';
    src: url('../fonts/Romul.eot') format('embedded-opentype'),
		 url('../fonts/Romul.woff') format('woff'),
		 url('../fonts/Romul.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

$color: #000;
$subcolor: #7C183E;
$color-light: #fff;

$text-font:'Pragmatica', sans-serif;
$subtext-font: 'Romul', sans-serif;
$p-font: 1.6rem;


* {
	box-sizing: border-box;
}
html {
  	height: 100%;
  	font-size: 10px;
}
body {
	font: 400 $p-font $text-font;
	height: 100%;
	color: $color;
	overflow-x: hidden;
	@media screen and (max-width: 576px) {
		font-size: 1.4rem;
	}    
}
//GLOBAL PROPERTIES
//
.modals {
	position: fixed;
	display: inline-block;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	z-index: 999999;
	background: url('../img/bg-ty.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0 0 150px 150px rgba(0,0,0,0.2); 
	text-align: center;
	color: #000;
	padding: 6em 3em;
	display: none;
	transition: 0.3s all linear;
	@media screen and (max-width: 768px) {
		// width: 350px;
	}
	&__title {
		font-size: 4rem;
		color: $color-light;
		padding-bottom: 15px;
		@media screen and (max-width: 768px) {
			font-size: 2.5rem;
		}		
	}
	&__subtitle {
		color: $color-light;

		font-size: 2.2rem;
		@media screen and (max-width: 768px) {
			font-size: 1.8rem;
		}
	}
}
a:hover,
a:focus {
	text-decoration: none;
	color: $subcolor;
	cursor: pointer;
}
.footer a:hover {
	text-decoration: underline;
	color: $color-light !important;
}
.form-group p {
	color: $color-light !important;
	
}
.color {
	&--font {
		color: $color;
	}
	&--sub {
		color: $subcolor;
	}
	&--light {
		color: $color-light;
	}
}
.fw-300 {
	font-weight: 300;
}
.fw-700 {
	font-weight: 700;
}

.fz-small {
	font-size: 2rem;
}
.fz-medium {
	font-size: 2.6rem;
	@media screen and (max-width: 768px) {
			font-size: 2rem;
			// text-align: center;
		}
}
.fz-big {
	font-size: 3.6rem;
}
.btn {
	padding: 10px 25px;
	font-weight: 700;
	transition: 0.2s all linear;
	font-size: 1.6rem;
	@media screen and (max-width: 768px) {
			font-size: 1.8rem;
			padding: 10px 20px;
			margin: 0 auto;
		}
	@media screen and (max-width: 350px) {
		font-size: 1.6rem;
		padding: 10px 10px;
		// margin: 0 auto;
	}
	&--first {
		background: transparent;
		border: 1px solid $subcolor;
		color: $subcolor;
		background: $color-light;
		&:hover {
			background: $subcolor;
			border: 1px solid $color-light;
			color: $color-light !important;
		}		
	}
	&--second {
		background: $subcolor;
		color: $color-light;
		border: 1px solid $subcolor;
		width: 315px;
		text-align: center;
		padding: 10px 0;
		&--small {
			width: 135px;
		}
		&:hover {
			background: transparent;
			color: $subcolor;
		}		
	}
	&--third {
		color: $color;
		&:hover {
			border: 1px solid $color;
			color: $color;
			padding: 10px 35px;
		}
	}
}
.title {
	position: relative;
	text-transform: uppercase;
	font-family: $subtext-font;
	margin: 1em;
	&--center {
		text-align: center;
		&:after {
			content: url('../img/line.png');
			bottom: 0;
			left: 50%;
			transform: translate(-50%,0);
			@media screen and (max-width: 786px) {
				position: absolute;
				content: '';
				width: 250px;
				height: 2px;
				background: $subcolor;

			}
		}
	}
	&__shadow {
		font-size: 7.5rem;
		opacity: 0.1;
		@media screen and (max-width: 786px) {
			font-size: 4rem;
		}
	}
	&__item {
		position: absolute;
		font-size: 3rem;
		@media screen and (max-width: 786px) {
			font-size: 2.2rem;
		}
		&--left {
			left: 3%;
			bottom: 15%;
		}
		&--center {
			bottom: 30%;
			left: 50%;
			transform: translate(-50%,0);
			@media screen and (max-width: 786px) {
				bottom: 10%;
			}
		}
	}
}
.article {
	&--indent {
		text-indent: 10%;
	}
}
.borders {
	border: 4px solid $subcolor;
	@media screen and (max-width: 576px) {
		border: 4px solid transparent;		
	}
}
//FOOTER
//
.footer {
	background: $subcolor;
	color: $color-light;
	@media screen and (max-width: 576px) {
		text-align: center;
		
	}
	& a:hover {
		color: $color;
	}
	&__info {
		font-size: 1rem;
		line-height: 1.4;
	}
}
//MENU
//
.design-nav {
	background: $color-light;
	color: $color;
}
.navbar__sub {
	background: $color;
	height: 100px;	
}
.navbar-toggler {
	@media screen and (max-width: 768px) {
		position: absolute;
		right: 2.5vh;
		top: 2.5vh;

	}
}
.menus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	& a {
		color: $color;
		&:hover {
			color: $subcolor;
		}
	}
	@media screen and (max-width: 576px) {
		display: block;
		position: relative;									
	}

	&__logo {
		width: 12%;
		padding-right: 1em;
		@media screen and (max-width: 768px) {
			padding-right: 1em;
			width: 20%;
		}
	}
	&__number {
		font-size: 3rem;
		@media screen and (max-width: 1366px) {
			font-size: 2.2rem;
		}
	}

}
.dropdown-menu {
	background: transparent;
	font-size: 1.6rem;
	text-align: center;
	& a {
		color: $color-light;
	}
}
.navbar-nav {
	@media screen and (max-width: 768px) {
		background: rgba(255,255,255,0.6);
		border-radius: 10px;
		color: $subcolor;
	}
}

.nav-item {
	position: relative;
	&:hover:after {
		opacity: 1;
		@media screen and (max-width: 576px) {
			opacity: 0;
		}
	}
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 50%;
		transform: translate(-50%,0);
		width: 65px;
		height: 6px;
		background: url('../img/line3.png');
		background-repeat: no-repeat;
		background-size: 100% 6px;
		opacity: 0;
		transition: 0.2s all linear;
	}
	&--course {
		&:after {
			background: url('../img/line2.png');
			width: 145px;
		}
	}
}
.active {
	&:after {
		opacity: 1 !important;
	}
}
.active + nav-item {
	&:after {
		opacity: 0 !important;
	}
}
//DROPDOWN TRANSITIONS
.dropdown .dropdown-menu {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

    max-height: 0;
    display: block;
    overflow: hidden;
    opacity: 0;
    padding: 0;
    margin: 0;
}

.dropdown.show .dropdown-menu { /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
    max-height: 300px;
    opacity: 1;
}
//END OF DROPDOWN TRANSITIONS
.section {
	background-repeat: no-repeat !important;
	background-size: cover !important;	
	&--bg {
		background: rgba(196, 196, 196, 0.1);
	}
	&--form {
		background: url('../img/bg-form.jpg');
		z-index: -1;
		position: relative;
		&--pad {
			margin-top: 3em;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $subcolor;
			mix-blend-mode: multiply;
			z-index: -1;
		}
		&--course1 {
			background: url('../img/course/form1.jpg');
				&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(124, 24, 62, 0.8);
				mix-blend-mode: normal;
				z-index: -1;
			}
		}

	}
	&--prof {
		background: url('../img/course/prof1.jpg');
		z-index: -1;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $color-light;
			opacity: 0.8;
			z-index: -1;
		}
	}
	&--general {
		background-image: url('../img/bg.jpg');
		@media screen and (max-width: 768px) {
			background-image: url('../img/bg.jpg');			
		}
	}
	&--position {
		position: relative;	
		overflow: hidden;	
	}
	&--topPad {
		margin-top: 5em;
	}
}
#home {
	position: relative;
}
.design-top {
	background: $color;
	transition: 0.2s all linear;
}
//FORM 
//
.form {
	&__call {
		margin-right: 5%;
		background: transparent;
		border: 1px solid $color-light;
		color: $color-light;
		font-size: 1.6rem;
		text-align: center;
		height: 50px;
	}
	&__title {
		font-size: 3.6rem;
		line-height: 1.2;
		color: $color-light;
		@media screen and (max-width: 768px) {
			font-size: 2.2rem;
			text-align: center;			
		}
	}
	&__subtitle {
		color: $color-light;
		padding-top: 1em;
		@media screen and (max-width: 768px) {
			text-align: center;
			padding-bottom: 0.5em;			
		}
	}
	&--padding {
		padding: 0 2em;
		@media screen and (max-width: 768px) {
			padding: 0;
			text-align: center;
			
		}
	}	
}
.form-group {
	&--small {
		display: flex;
		margin: 1.5em 0;
		@media screen and (max-width: 576px) {
			flex-direction: column;
		}
	}
}
.formSet {
	@media screen and (max-width: 576px) {
		text-align: center;		
	}
}
//FIRST 
//
.first {
	&__logo {
		@media screen and (max-width: 1366px) {
			width: 50%;	
		}
	}
	&__title {
		font-weight: 700;
		font-size: 6.8rem;
		color: $subcolor;
		text-transform: uppercase;
		padding-top: 0.2em;
		line-height: 1.1;
		&--course {
			font-family: $subtext-font;
			font-weight: 400;
		}
		@media screen and (max-width: 1366px) {
			font-size: 6rem;	
		}
		@media screen and (max-width: 768px) {
			font-size: 5rem;					
		}
	}
	&__subtitle {
		font-size: 4rem;
		padding-bottom: 0.3em;
		&--course {
			font-size: 2.5rem;
			color: $subcolor;
		}
		@media screen and (max-width: 1366px) {
			font-size: 3.5rem;	
		}	
		@media screen and (max-width: 768px) {
			font-size: 2.5rem;	
		}	
	}
	&__course {
		font-size: 3.5rem;
		text-transform: uppercase;
		color: $subcolor;
		font-family: $subtext-font;	
		@media screen and (max-width: 768px) {
			font-size: 3rem;			
		}	
	}
	&__date {
		font-size: 2rem;
		font-weight: 700;
		padding: 0.5em 0;
	}
	&__courses {
		z-index: 2;
		@media screen and (max-width: 768px) {
			margin: 0 20px;
			text-align: center;
			
		}
	}
}
.quotes {
	font-weight: 300;
	font-style: oblique;
	font-size: 2.2rem;
	&--left {
		text-align: left;
	}
	&--right {
		text-align: right;
	}
}
//ABOUT 
//
.about {
	height: 380px;
	position: relative;
	@media screen and (max-width: 768px) {
			height: auto;
			margin: 1em 0;
		}
	&__item {
		padding: 20px 30px;
		@media screen and (max-width: 768px) {
			padding: 0;
		}
		& img {
			@media screen and (max-width: 768px) {
			width: 100%;
		}
		}
	}
	&__video {
		position: absolute;
		bottom: -50%;
		left: -20%;
		@media screen and (max-width: 768px) {
			display: none;

		}

	}
}
//COURSE 
//
.course {
	height: 460px;
	position: relative;
	margin: 2em 0;
	&__item {
		background-repeat: no-repeat !important;
		background-size: cover !important;
		position: absolute;
		height: 460px;
		top: -7%;
		right: -5%;
		width: 100%;
		@media screen and (max-width: 768px) {
			right: 0;
		}
		&__footer {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			background: $subcolor;
			padding: 1em;
		}
	}
}
//GALLERY 
//
.gallery {
	display: grid;
	grid-template-rows:  repeat(3, 1fr);
	grid-template-columns: repeat(12, 1fr);
	grid-template-areas: 
	"g1 g1 g1 g1 g1 g2 g2 g2 g2 g2 g2 g2"
	"g1 g1 g1 g1 g1 g3 g3 g3 g4 g4 g4 g4"
	"g5 g5 g5 g5 g5 g5 g5 g5 g4 g4 g4 g4";
	grid-gap: 1.5vw;
	height: 500px;
	margin: 2em 0;
	@media screen and (max-width: 576px) {
		grid-template-rows:  repeat(5, 1fr);
		grid-template-areas: 
		"g1 g1 g1 g1 g1 g1 g1 g1 g1 g1 g1 g1"
		"g2 g2 g2 g2 g2 g2 g2 g2 g2 g2 g2 g2"
		"g3 g3 g3 g3 g3 g3 g3 g3 g3 g3 g3 g3"
		"g4 g4 g4 g4 g4 g4 g4 g4 g4 g4 g4 g4"
		"g5 g5 g5 g5 g5 g5 g5 g5 g5 g5 g5 g5";
	}
}
.gallery a {
	overflow: hidden;
}
#gallery1 {
	grid-area: g1;
}
#gallery2 {
	grid-area: g2;
}
#gallery3 {
	grid-area: g3;
}
#gallery4 {
	grid-area: g4;
}
#gallery5 {
	grid-area: g5;
}
//PAGE COURSES
//
.courses {
	&__rectangle {
		position: absolute;
		left: -10%;
		top: -15%;
		width: 60vw;
		height: 130vh;
		background: $color-light;
		opacity: 0.9;
		transform: rotateZ(20deg);
		z-index: 0;
		@media screen and (max-width: 768px) {
			width: 200vw;
			left: -25%;
		}
	}
}
//SECTION KNOW
//
.know {
	padding-bottom: 6em;
	@media screen and (max-width: 768px) {
		padding-bottom: 0em;
	}
	&__number {
		text-align: center;
		font-size: 1.8rem;		
		width: 35px;
		height: 35px;
		line-height: 35px;
		border: 2px solid $subcolor;
		border-radius: 50%;
		margin-right: 1em;
	}
	&__article {
		width: 80%;
	}
	&__image {
		position: relative;
		@media screen and (max-width: 768px) {
			margin-bottom: 1em;
		}
		&--item {
			position: absolute;
			width: 400px;
			left: 30%;
			top: 50%;
			overflow: hidden;
		}
	}
}
//SECTION PROF 
//
.prof {
	position: relative;
	&__main {
		@media screen and (max-width: 768px) {
			text-align: center;
			
		}
	}
	&__left {
		&--first {
			position: absolute;
			width: 200px;
			overflow: hidden;
			top: -10%;
			left: -10%;
		}
	}
	&__right {
		&--first {
			position: absolute;
			bottom: -10%;
			right: -10%;
		}
	}
}
//SECTION PROGRAMS 
//
.btn-link {
	width: 100%;
	text-align: left;
	font-size: 2.5rem;
	color: $subcolor;
	font-weight: 400;
	padding: 7px 25px;
	border: 2px solid $subcolor;
	margin: 0.4em;
	@media screen and (max-width: 768px) {
		margin: 0.4em 0;
	}
	&:hover,
	&:focus,
	&:visited,
	&:active {
		color: $color-light;
		background: $subcolor;
		text-decoration: none;
		
	}
}
.card-body {
	padding: 1.5em 1em;
	padding-bottom: 6em;
}
.programs {
	&__image {
		position: relative;
		@media screen and (max-width: 768px) {
			display: none;
		}
		&--item {
			width: 280px;
			position: absolute;
			left: 10%;
			top: 10%;
		}
	}
	&__title {
		font-weight: 700;
		font-size: 2.2rem;
		padding-bottom: 1em;
	}
}
//SECTION MATERIAL
//
.material {
	transform: rotateZ(180deg);
	&__image {
		height: 360px;
		position: relative;
		@media screen and (max-width: 768px) {
				height: 220px;
			}

		&--item {
			overflow: hidden;
			width: 550px;
			position: absolute;
			top: 5%;
			left: 5%;
			height: 325px;
			@media screen and (max-width: 768px) {
				left: 0;
				width: 100%;
				height: auto;
				
			}
		}		
	}
	&__icon {
		transform: rotateZ(180deg);
	}
}
//SECTION FORWHO
//
.forWho {
	&__image {
		position: relative;

		&--item {
			position: absolute;
			top: -15%;
			left: 20%;
			width: 420px;
		}
	}
}
//SECTION TEACHER
//
.teacher {
	&__image {
		position: relative;
		&--item {
			position: absolute;
			top: 5%;
			left: 15%;
		}
	}
}
//SECTION TESTIMONIAL
//
.testimonial {
	box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
	height: 600px;
	display: block;
	
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 15%;
		padding: 0 1em;
		&__profile {
			border-radius: 50%;
			overflow: hidden;
			width: 60px;
			height: 60px;
			margin-right: 1em;
		}
	}
	&__main {
		height: 35%;
		overflow: hidden;
	}
	&__footer {
		height: 55%;
		overflow: hidden;
		padding: 0.5em 1em;
		padding-bottom: 3em;
	}

}
//GALLERY ABOUT
//
.gallers {
	// margin-bottom: 1em;
	&__item {				
		& img {
			border-radius: 50%;
			border: 2px solid $subcolor;
			padding-right: 0.4em;
			display: block;		
			overflow: hidden;
			width: 250px !important;
			height: 250px;
			margin: 0 auto;
		}		
	}
}
//ADAPTIVE
//
.dnone-md {
	@media screen and (max-width: 576px) {
		display: none;
	}
}
.dnone-lg {
	@media screen and (max-width: 768px) {
		display: none;
	}
}
.db-md {
	@media screen and (min-width: 576px) {
		display: none;
	}
}
input[type="name"]::-webkit-input-placeholder {color: rgba(255,255,255, 0.5);}
input[type="name"]::-moz-placeholder          {color: rgba(255,255,255, 0.5);}/* Firefox 19+ */
input[type="name"]:-moz-placeholder           {color: rgba(255,255,255, 0.5);}/* Firefox 18- */
input[type="name"]:-ms-input-placeholder      {color: rgba(255,255,255, 0.5);}
input[type="text"]::-webkit-input-placeholder {color: rgba(255,255,255, 0.5);}
input[type="text"]::-moz-placeholder          {color: rgba(255,255,255, 0.5);}/* Firefox 19+ */
input[type="text"]:-moz-placeholder           {color: rgba(255,255,255, 0.5);}/* Firefox 18- */
input[type="text"]:-ms-input-placeholder      {color: rgba(255,255,255, 0.5);}

//COSTULI
//
.card-body {
	min-height: 350px;
	@media screen and (max-width: 576px) {
		min-height: 250px;
	}
}
.modal-dialog {
	max-width: 1200px !important;
	@media screen and (max-width: 768px) {
		max-width: 100%;
	}
}
.modal-content {
	padding: 3em;
}
.messageName, 
.messagePhone {
	color: $color-light;
}
